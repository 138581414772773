import React from 'react'

import Layout from '../components/layout'

const Index = () => (
  <Layout>
  </Layout>
)

export default Index;
